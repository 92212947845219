// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-routes-forgot-password-page-index-tsx": () => import("./../src/routes/ForgotPasswordPage/index.tsx" /* webpackChunkName: "component---src-routes-forgot-password-page-index-tsx" */),
  "component---src-routes-home-page-index-tsx": () => import("./../src/routes/HomePage/index.tsx" /* webpackChunkName: "component---src-routes-home-page-index-tsx" */),
  "component---src-routes-sign-in-page-index-tsx": () => import("./../src/routes/SignInPage/index.tsx" /* webpackChunkName: "component---src-routes-sign-in-page-index-tsx" */)
}

